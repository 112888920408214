import BaseServices from '../../helpers/BaseServices';
import { API_EMAIL } from "./constants";
import RequestUtils from "../../config/requestUtils";

export default class Services extends BaseServices {

    busca = (modalidade, tipo, endereco, filtros = [], page, order = 'menor-valor', limit = 10, offset = 1, caracSelecionados = [], caracSelecionadosCondominio = []) => {

        let currentPage = (page ? page : 1);

        let url = RequestUtils.uriResolver(modalidade);
        let method = RequestUtils.methodResolver('busca', modalidade);

        url += `${method}?limit=${limit}&offset=${offset}&page=${currentPage}`;

        if (tipo.indexOf("todos-os-tipos") === -1 && tipo.length > 0) {
            let tipos = tipo.toString();
            url += `&tipo=${tipos.replace('-', ' ')}`;
        }

        if (endereco.indexOf("todos-os-bairros-e-cidades") === -1 && endereco.length > 0) {
            let enderecos = endereco.toString();
            url += `&endereco=${enderecos}`;
        }

        if (filtros) {
            for (let i in filtros) {
                if (filtros[i] === '' || filtros[i] === null || filtros[i] === undefined) continue;
                url += `&${i}=${filtros[i]}`;
            }
        }

        if (caracSelecionados.length > 0) {
            let list = caracSelecionados.map(item => [
                item.value
            ]);
            url += `&caracteristicas=${list}`;
        }

        if (caracSelecionadosCondominio.length > 0) {
            let list = caracSelecionadosCondominio.map(item => [
                item.value
            ]);
            url += `&caracsCondominio=${list}`;
        }

        return fetch(url, BaseServices._getRequestInfo(modalidade))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    };

    getCaracteristicasImovel = (modalidade, tipo) => {
        let url = RequestUtils.uriResolver(modalidade);
        let method = RequestUtils.methodResolver('caracteristicas', modalidade);

        return fetch(`${url}${method}/${tipo}`, BaseServices._getRequestInfo(modalidade))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }
                return response.json();
            });
    }

    getCaracteristicasCondominio = (modalidade, tipo) => {
        let url = RequestUtils.uriResolver(modalidade);
        let method = RequestUtils.methodResolver('caracteristicasCondominio', modalidade);

        return fetch(`${url}${method}/${tipo}`, BaseServices._getRequestInfo(modalidade))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }
                return response.json();
            });
    }

    solicitarInformacoesImovel = (dados) => {
        return fetch(`${API_EMAIL}/solicitarInformacoesImovel`, BaseServices._getRequestInfo('venda','POST', `${JSON.stringify(dados)}`))
          .then(response => {
            if (!response.ok) {
              throw response.json();          
            }
    
            return response.json();
        });
    }
}