import BaseServices from '../../../helpers/BaseServices';
import { API_VENDAS, API_CADIMO5 } from "./constants";
import RequestUtils from '../../../config/requestUtils';

export default class Services extends BaseServices {
    constructor() {
        super(API_VENDAS, API_CADIMO5);
    };

    getTiposImoveisOptions = (modalidade) => {
        let url = RequestUtils.uriResolver(modalidade);
        let method = RequestUtils.methodResolver('tipos', modalidade);

        return fetch(`${url}${method}`, BaseServices._getRequestInfo(modalidade))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    }

    getbuscaEndereco = (modalidade, palavra) => {
        let url = RequestUtils.uriResolver(modalidade);
        let method = RequestUtils.methodResolver('buscaEndereco', modalidade);

        return fetch(`${url}${method}${palavra}`, BaseServices._getRequestInfo(modalidade))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    }
}