import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FilterList from './FilterList';
import Util from './../../../helpers/Util';

class ListTabs extends Component {

    render() {
        let links;

        if (this.props.active === 'venda') {
            links =
                <ul>
                    <li><Link id="venda" className="active" to="" onClick={() => this.props.mudarFinalidade(`/busca/venda/${Util.transliterate(this.props.tipo)}/${this.props.endereco}${this.props.filtrosAdicionados}`)}>Comprar</Link></li>
                    <li><Link id="aluguel" to="" onClick={() => this.props.mudarFinalidade(`/busca/aluguel/${Util.transliterate(this.props.tipo)}/${this.props.endereco}${this.props.filtrosAdicionados}`)}>Alugar</Link></li>
                </ul>
        }

        if (this.props.active === 'aluguel') {
            links =
                <ul>
                    <li><Link id="venda" to="" onClick={() => this.props.mudarFinalidade(`/busca/venda/${Util.transliterate(this.props.tipo)}/${this.props.endereco}${this.props.filtrosAdicionados}`)}>Comprar</Link></li>
                    <li><Link  id="aluguel" className="active" to="" onClick={() => this.props.mudarFinalidade(`/busca/aluguel/${Util.transliterate(this.props.tipo)}/${this.props.endereco}${this.props.filtrosAdicionados}`)}>Alugar</Link></li>
                </ul>
        }

        return (
            <div className="list-tabs">
                <div className="nav-itens">
                    { links }
                </div>
                <FilterList
                    {...this.props}
                    handleOnChangeOrder={e => this.props.handleOnChangeOrder(e)}/>
            </div>
        );
    }
}

export default ListTabs;