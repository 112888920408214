import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import ModalContato from './ModalContato';
import './header.css';
import FavoritosHelper from "../../../helpers/Favoritos";

class Header extends Component {

    constructor (props) {
        super(props);
        this._favoritos = new FavoritosHelper();
    }

    openMenu = () =>{
        const element = document.querySelector('div#root');
        element.classList.add('open-menu');
    };

    mudarFinalidade = (url) => {
        window.location = url;
    };

    render() {
        return (
            <header>
                <div className="topo">
                    <div className="container">
                        <div className="contato">
                            <a href='https://api.whatsapp.com/send/?phone=558533053000&text&app_absent=0' target='_blank' rel="noopener noreferrer">
                                <span className="icone"><i className="fa fa-phone"></i> (85) 3305-3000</span>
                            </a>
                            {/* | &nbsp;&nbsp;                            
                            <span className="icone" data-toggle="modal" data-target="#modalContato" style={{cursor: 'pointer'}}><i className="fa fa-whatsapp"></i> Nossos contatos</span>                             */}
                            | &nbsp;&nbsp;
                            <span className="icone"><i className="fa fa-envelope-o"></i> comercial@cesarrego.com.br</span>
                        </div>
                    </div>
                </div>

                <ModalContato modalName="modalContato"/>
               
                <div className="container">
                    <div className="col-left">
                        <div className="logomarca">
                            <h2>César Rêgo Imóveis</h2>
                            <Link to="/">
                                <img src="/images/logomarca/logomarca.png" alt="Logomarca César Rêgo imóveis" width="100%" height="auto" />
                            </Link>
                        </div>
                        <div className="nav">
                            <nav>
                                <li>
                                    <Link to="" onClick={() => this.mudarFinalidade('/busca/venda/apartamento/fortaleza')}>Comprar</Link>
                                </li>
                                <li>
                                    <Link to="" onClick={() => this.mudarFinalidade('/busca/aluguel/apartamento/fortaleza')}>Alugar</Link>
                                </li>
                            </nav>
                        </div>
                    </div>
                    <div className="col-right">
                        <div className="fav-link">
                            <Link to="/favoritos">
                                <i className="fa fa-star"></i>
                                <span id='totalizadorFavorito' className="fav-count">{this._favoritos.getQuantidadeFavoritos()}</span>
                                <span className="fav-text">Favoritos</span>
                            </Link>
                        </div>

                        <div className="area-cliente">
                            <a className="botao azul" href="https://areaclienteweb.com.br/apps/AreaClienteWeb.dll?token=[ákYj8j]_g8aegn]akSHHIQMHIIHHHIOL" rel="noopener noreferrer" target="_blank">
                                <i className="fa fa-sign-in"></i> &nbsp;
                                Área cliente
                            </a>
                        </div>
                        <div className="menu">
                            <span onClick={this.openMenu}>
                                <i className="fa fa-th"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;