import React, { Component } from 'react';
import ListTabs from "./ListTabs";
import Util from './../../../helpers/Util';
import FilterList from './FilterList';
import CardImovel from "../../../components/Card/CardImovel";
import { Segment } from 'semantic-ui-react';
import Pagination from "react-js-pagination";

class Content extends Component {    
    constructor(props) {        
        super(props);
            this.state = {
            activePage: 1
        };
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.offset === 0) { 
            this.setState({activePage: 1});
        }
    }

    handlePageChange = (pageNumber) => {        
        this.setState({activePage: pageNumber});
        this.props.handlePaginacao('offset', ((this.props.limit * pageNumber) - this.props.limit))
    }

    openSearch = () =>{
        const element = document.querySelector('div#root');
        element.classList.add('open-search');
    };

    adicionarTagsFiltros() {
        let filtros = this.props.filtros;
        let valores = [];
        let area = [];
        let caracsImovel = [];
        let caracsEdificio = [];        
        let resultado = [];

        if (filtros !== '') {
            for (let i in filtros) {
                if (filtros[i] !== ''){
                    if (i !== 'valorInicial' && i !== 'valorFinal' && i !== 'areaInicial'  && i !== 'areaFinal' && i !== 'order' ) {
                        resultado.push(filtros[i] + '+ ' + i);   
                    }                    
                    
                    if (i === 'valorInicial') { 
                        valores.unshift("A partir de R$ " + parseFloat(filtros[i]).toLocaleString('pt-BR', {                           
                            minimumFractionDigits: 2,  
                            maximumFractionDigits: 2
                        }))
                    };                    
                    if (i === 'valorFinal') { 
                        valores.push("Até R$ " + parseFloat(filtros[i]).toLocaleString('pt-BR', {                           
                            minimumFractionDigits: 2,  
                            maximumFractionDigits: 2
                        }))
                    };

                    if (i === 'areaInicial'){ area.unshift(`A partir de ${filtros[i]} m²`)};
                    if (i === 'areaFinal'){ area.push(`Até ${filtros[i]} m²`)};
                }           
            }
            
            if (this.props.caracSelecionados.length > 0) {
                this.props.caracSelecionados.map(item => [
                    caracsImovel.push('+' + item.name)
                ]);
            }

            if (this.props.caracSelecionadosCondominio.length > 0) {
                this.props.caracSelecionadosCondominio.map(item => [
                    caracsEdificio.push('+' + item.name)
                ]);
            }

            return resultado.concat(valores, area).concat(caracsImovel).concat(caracsEdificio);
        }
    }    

    render() {
        
        let title = '';

        if (this.props.total > 0) {
            title = `
            ${this.props.total} imóvel(is) do tipo ${Util.retiraHifen(this.props.tipo).toLowerCase()}
            ${this.props.finalidade === 'venda' ? ' à venda ' : ' para alugar '} em 
            ${Util.ucWords(Util.retiraHifen(this.props.endereco))}`;
        } else {
            title = 'Não foram encontrados imóveis para a busca realizada';
        }

        let paginacao;
        if (this.props.total > this.props.limit) {
            paginacao = 
            <div className="paginacao">
                <nav>
                    <Pagination
                        hideDisabled
                        prevPageText='Anterior'
                        nextPageText='Próximo'
                        firstPageText='Início'
                        lastPageText='Fim'
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.props.limit}
                        totalItemsCount={this.props.total}
                        onChange={this.handlePageChange}
                    />
                </nav>
            </div>
        } else {
            paginacao = ''
        }
        
        return (
            <Segment loading={this.props.loadingContent} className="segment-content-resultado-de-busca">
                <div className="content-resultado-de-busca">
                    <div className="header-content">
                        <ListTabs
                            {...this.props}
                            handleOnChangeOrder={e => this.props.handleOnChangeOrder(e)}
                            mudarFinalidade={(url) => this.props.mudarFinalidade(url)}
                            active={this.props.finalidade}
                            tipo={this.props.tipo}
                            endereco={this.props.endereco}
                        />
                        <div className="search-info">
                            <h1>{title}</h1>

                            <span>{Util.capitalize(Util.retiraHifen(this.props.tipo))}</span>
                            <span>{Util.ucWords(Util.retiraHifen(this.props.endereco))}</span>
                            {this.adicionarTagsFiltros().map((tags, index) =>(
                                <span key={index}>{tags}</span>
                            ))}

                        </div>
                        <div className="search-mobile">
                            <div className="link">
                                <span id="open-search" onClick={this.openSearch} className="btn botao azul">
                                    <i className="fa fa-search"></i> Busca
                                </span>
                            </div>
                            <FilterList {...this.props} handleSelect={e => this.props.handleSelect(e)}/>
                        </div>
                    </div>
                    <div className="body-content">
                        <CardImovel finalidade={this.props.finalidade.toLowerCase()} cards={this.props.imoveis} icon="star"/>
                    </div>

                    {paginacao}
                    
                </div>
            </Segment>
        );
    }
}

export default Content;