import React, { Component} from 'react';
import { Link } from "react-router-dom";
import './navbar.css';

class Navbar extends Component {

    closeMenu = () =>{
        const element = document.querySelector('div#root');
        element.classList.remove('open-menu');
    }

    mudarFinalidade = (url) => {
        this.closeMenu();
        window.location = url;
    };

    render () {
       return (
           <div className="navegacao">
               <div className="topo">
                   <h2>Menu</h2>
                   <h3 className="btn-close" onClick={this.closeMenu}><i className="fa fa-times"></i></h3>
               </div>

               <ul>
                   <li>
                       <Link to="" onClick={() => this.mudarFinalidade('/busca/venda/apartamento/fortaleza')}>
                           <span><i className="fa fa-home"></i>Imóveis à venda</span>
                       </Link>
                   </li>
                   <li>
                       <Link to="" onClick={() => this.mudarFinalidade('/busca/aluguel/apartamento/fortaleza')}>
                           <span><i className="fa fa-key"></i>Imóveis para alugar</span>
                       </Link>
                   </li>
                   <li>
                       <Link to="/cadastre_seu_imovel" onClick={this.closeMenu}>
                           <span><i className="fa fa-bullhorn"></i> Quero vender meu imóvel</span>
                       </Link>
                   </li>
                   <li>
                       <Link to="/cadastre_seu_imovel" onClick={this.closeMenu}>
                           <span><i className="fa fa-bullhorn"></i> Quero alugar meu imóvel</span>
                       </Link>
                   </li>
               </ul>

                <div className="btn-whatsapp">
                    <a href='https://api.whatsapp.com/send/?phone=558533053000&text&app_absent=0' target='_blank' rel="noopener noreferrer" onClick={this.closeMenu}>
                        <i className="fa fa-whatsapp"></i> Entre em contato via WhatsApp
                    </a>
                </div>

               <h3><i className="fa fa-user"></i> Área cliente</h3>
               <div className="area-cliente-nav">
                   <ul>
                       <li>
                           <a href="https://areaclienteweb.com.br/apps/AreaClienteWeb.dll?token=[ákYj8j]_g8aegn]akSHHIQMHIIHHHIOL" onClick={this.closeMenu} target="_blank" rel="noopener noreferrer">
                               <i className="fa fa-barcode"></i> Segunda via de boleto
                           </a>
                       </li>
                       <li>
                           <a href="https://areaclienteweb.com.br/apps/AreaClienteWeb.dll?token=[ákYj8j]_g8aegn]akSHHIQMHIIHHHIOL" onClick={this.closeMenu} target="_blank" rel="noopener noreferrer">
                               <i className="fa fa-calculator"></i> Extrato de prestação de contas
                           </a>
                       </li>
                       <li>
                           <a href="https://areaclienteweb.com.br/apps/AreaClienteWeb.dll?token=[ákYj8j]_g8aegn]akSHHIQMHIIHHHIOL" onClick={this.closeMenu} target="_blank" rel="noopener noreferrer">
                               <i className="fa fa-usd"></i>Extrato de IRRF
                           </a>
                       </li>
                   </ul>
               </div>
           </div>
       );
   }
}

export default Navbar;