import React, {Component} from 'react';
import ListFeatures from './../../../components/Features/ListFeatures';

const Title = (props) => {
    let text;
    if (props.finalidade === "Residencial") {
        text = `Detalhes do imóvel ${props.tipo} de ${props.dormitorios} dormitórios no bairro ${props.bairro}`
    } else {
        text = `Detalhes do imóvel do tipo ${props.tipo} no bairro ${props.bairro}`
    }
    
    return (
        <div className="detail">
            <div className="detail-title">
                <h3> {text} </h3>
            </div>
            <p className="description-wrap">
                {props.descricao}
            </p>
        </div>
    );
}

class DetalhesDoImovel extends Component {
    render() {
        return (
            <div className="block-detail">
                <Title
                    tipo={this.props.data.tipo}
                    finalidade={this.props.data.finalidade}
                    dormitorios={parseInt(this.props.data.quartos) > 0 ? parseInt(this.props.data.quartos) : parseInt(this.props.data.suites)}
                    bairro={this.props.data.bairro}
                    descricao={this.props.data.descricao}

                />
                <div className="feature">
                    {(this.props.data.caracteristicas && this.props.data.caracteristicas.length > 0 ?
                    <ListFeatures title="Diferenciais do imóvel" list={this.props.data.caracteristicas}/> : '')}                    

                    {(this.props.data.condominio && this.props.data.condominio.length > 0 ?
                    <ListFeatures title="Diferenciais do edifício" list={this.props.data.condominio}/> : '')}
                    
                    {/* <ListFeatures title="Cômodos com armários" list={this.props.data.armarios}/>
                    <ListFeatures title="Tipos de pisos" list={this.props.data.pisos}/>
                    <ListFeatures title="Revestimento externo" list={this.props.data.revestimentoExterno}/> */}
                </div>
            </div>
        );
    }
}
export default DetalhesDoImovel;
