import React from 'react';
import {Form, FormGroup, Input, Button} from "reactstrap";
import MaskedInput from 'react-text-mask';
import Util from './../../../../helpers/Util';
import Services from './../../Services';
import './modalContatoImovel.css';

class ModalContatoImovel extends React.Component {

  constructor(props) {
    super(props);
    this._services = new Services(); 
    this.state = {
      nome:'',
      email:'',
      textMail: '',
      telefone:'',
      mensagem: '',
      contato: '',
      fields: {},
      loading: false   
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {      
    if (nextProps.fields.codigo !== prevState.fields.codigo) {
        return {
            ...nextProps,
            mensagem: 'Olá, gostaria de receber mais informações sobre o imóvel no bairro '
            + nextProps.fields.bairro + ', '
            + Util.capitalize(nextProps.fields.cidade) + ' - '
            + Util.capitalize(nextProps.fields.estado) + '. O código do imóvel é '
            + nextProps.fields.codigo +'. Aguardo retorno.'
        };
    }
    return null;
}

  handleOnChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({loading: true, show: false});

    let url = `https://www.cesarrego.com.br/agradecimento-contato-${this.state.fields.modalidade}`;   
    window.gtag_report_conversion(url);

    if(this.state.modalidade === 'venda'){            
        window.chama();  
    }
          
    let dados = 
    {   
        "codigo": this.state.fields.codigo,
        "modalidade": this.state.fields.modalidade,
        "link": `https://www.cesarrego.com.br/detalhes/${this.state.fields.modalidade}/${this.state.fields.codigo}`,
        "titulo": 'Imóvel',
        "nome": this.state.nome,
        "email": (this.state.fields.modalidade === 'venda' ? this.state.email : this.state.textMail), 
        "telefone": this.state.telefone,
        "mensagem": this.state.mensagem
    }
    
    this._services.solicitarInformacoesImovel(dados)            
        .then(api => {
            this.setState({loading: false});
            if (api.code === 1) {                    
                document.interesseImovelRD.action=`/agradecimento-contato-${this.state.fields.modalidade}`;
                document.interesseImovelRD.submit();
            }
            if (api.code === 2) {
                this.setState({
                    apiMessage: `${api.message} Repita a operação.`,
                    show: true
                })
            }                                
        });
  }  

  handleOnChangeFinalidade = (e) => {
    this.setState({
        [e.target.name]: (e.target.value !== null ? e.target.value : '')
    });
  };

  render() {
    return (
      <div className={`modal fade`} id='modalContatoImovel' tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h2 className="modal-title" id="myModalLabel">Saiba mais sobre este imóvel</h2>
                  </div>
                  <div className="modal-body">                        
                    <Form onSubmit={(e) => this.handleClick(e)} name="interesseImovelRD">                    
                      <FormGroup>
                          <Input 
                              name="nome" 
                              id="iptNome" 
                              type="text" 
                              placeholder="Seu nome" 
                              required 
                              onChange={e => this.handleOnChange(e)} 
                              value={this.state.nome} 
                          />
                      </FormGroup>
                      <FormGroup>
                          <Input 
                              name={this.state.fields.modalidade === 'venda' ? "email" : "textMail"} 
                              id="iptEmail" 
                              type={this.state.fields.modalidade === 'venda' ? "email" : "text"} 
                              placeholder="Seu e-mail" 
                              required  
                              onChange={e => this.handleOnChange(e)} 
                              value={this.state.fields.modalidade === 'venda' ? this.state.email : this.state.textMail} 
                          />
                      </FormGroup>
                      <FormGroup>                            
                          <Input
                              // id="form-fluid-departamento" 
                              type="select"
                              name="contato"
                              onChange={(e) => this.handleOnChangeFinalidade(e)}
                              required
                          >
                              <option value="">Por onde você deseja ser Contactado</option>
                              <option value="whatsapp">Whatsapp</option>
                              <option value="ligacao">Ligação</option>
                              <option value="email">Email</option>
                          </Input>
                      </FormGroup> 
                      <MaskedInput
                              mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                              className="form-control"
                              placeholder="Seu celular"                            
                              guide={false}
                              name='telefone'
                              id="iptTelefone"
                              required={true}
                              value={this.state.telefone}
                              onChange={e => this.handleOnChange(e)}
                      />                                      
                      <FormGroup>
                          <Input name="mensagem" id="iptMensagem" type="textarea" rows="3" onChange={e => this.handleOnChange(e)} value={this.state.mensagem} />
                      </FormGroup>
                         
                      <Button type='submit' className="btn botao">
                          Receber informações
                      </Button>                    
                    </Form>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default ModalContatoImovel;
