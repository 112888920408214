import React, { Component } from 'react';
import Features from "./Features";
import Carousel from "./Carousel";
import Highlight from "./Highlight";
import './cardImovel.css';
import Favoritos from "../../helpers/Favoritos";
import Util from "./../../helpers/Util";
import ModalContatoImovel from '../../pages/ResultadoDeBusca/components/ModalContatoImovel';

class CardImovel extends Component {
    constructor(props){
        super(props);
        this._favoritos = new Favoritos();
        this.state = { fields: {} }
    }

    getSlug(tipo, quartos, suites, bairro, cidade, estado) {
        let dormitorios = 0;
        let slug = '';

        dormitorios = (quartos > 0 ? quartos : suites);
         if (dormitorios === 1) {
            slug = `${tipo}-${dormitorios}-dormitorio-no-bairro-${bairro}-em-${cidade}-${estado}`;
         } else if (dormitorios > 1) {
            slug = `${tipo}-${dormitorios}-dormitorios-no-bairro-${bairro}-em-${cidade}-${estado}`;
         } else {
            slug = `${tipo}-no-bairro-${bairro}-${cidade}-${estado}`;
         }

         return Util.transliterate(slug);
    }

    getFields = (modalidade, bairro, cidade, estado, codigo) => {
        this.setState({ fields: {modalidade, bairro, cidade, estado, codigo } })
    }

    render() {
        return (
            <div className="row">
                {this.props.cards.map((card, index) => (
                   <div key={'card_'+index} id={'card_'+index} className="cardImovel">

                        <Highlight destaque={card.destaque} lancamento={card.status_comercial}/>
                        <Carousel 
                            link={`/detalhes/${card.modalidade.toLowerCase()}/${card.id}/${this.getSlug(card.tipo, card.quartos, card.suites, card.bairro, card.cidade, card.estado)}`}
                            fotos={card.imagens} 
                            codigo={card.id}
                            tour={card.tour}
                        />

                        <div className="wrap">
                            <span className={(window.innerWidth > 767 ? "icon-top" : "icon-bottom")} onClick={() => this._favoritos.adicionarRemoverFavoritos(card)}>
                                <i id={'icon_'+card.id} className={this._favoritos.verificarFavorito(card.id, this.props.icon)}></i>
                            </span>
                            <h2>
                                <a href={`/detalhes/${card.modalidade.toLowerCase()}/${card.id}/${this.getSlug(card.tipo, card.quartos, card.suites, card.bairro, card.cidade, card.estado)}`}>
                                    {card.tipo} 
                                    {card.modalidade === 'venda' ? ' à venda ' : ' para alugar '}
                                    no bairro&nbsp; 
                                    {Util.ucWords(card.bairro)}
                                </a>
                            </h2>
                            <h3>
                                <a 
                                    href={`/detalhes/${card.modalidade.toLowerCase()}/${card.id}/${this.getSlug(card.tipo, card.quartos, card.suites, card.bairro, card.cidade, card.estado)}`}>
                                    <i className="fa fa-map-marker"></i>&nbsp;
                                    {Util.ucWords(card.bairro)}, {Util.ucWords(card.cidade)} - {card.estado}
                                </a>
                            </h3>

                            <Features                                
                                area={(card.area_util === 0 ?  card.area_total : card.area_util)}
                                quartos={card.quartos}
                                suites={card.suites}
                                banheiros={card.banheiros}
                                garagem={card.vagas}
                            />

                            <h2 className="descricao">
                                {card.descricao}
                            </h2>

                            <div className="valor">
                                <h3>R$ {card.valor.replace(',00','')}</h3>
                                <span>
                                    <a className="btn botao azul" href={`/detalhes/${card.modalidade.toLowerCase()}/${card.id}/${this.getSlug(card.tipo, card.quartos, card.suites, card.bairro, card.cidade, card.estado)}`}>Detalhes do imóvel</a>
                                    <button onClick={() => this.getFields(card.modalidade, card.bairro, card.cidade, card.estado, card.codigo)} className="btn botao azul" data-toggle="modal" data-target="#modalContatoImovel">Contatar</button>
                                </span>
                            </div>

                            <div className="complementos">
                                <h3>
                                    {card.valor_condominio !== '0,00' ? `Condomínio: R$ ${card.valor_condominio}` : ``}
                                    {card.valor_iptu > 0? ` | IPTU: R$ ${card.valor_iptu}` : ``}
                                </h3>
                            </div>

                            <div className="codigo">
                                <h3>Código: <span>{card.codigo}</span></h3>
                            </div>                           
                        </div>
                    </div>
                ))}

                <ModalContatoImovel fields={this.state.fields} />
            </div>
        );
    }
}

export default CardImovel;
